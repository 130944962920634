.wrapper {
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }
  .wrapper button{
      padding: 10px 20px;
      background: #614101;
      color: #fff;
      font-weight: bold;
      font-size: 20px;
      cursor: pointer;
  }
  .accordian {
    width: 500px;
  }
  .item {
    background: #614101;
    margin-bottom: 10px;
    padding: 10px 20px;
  }
  .title {
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .content {
    color: #fff;
    height: auto;
  }
  